/* * {
  border: 2px solid red;
} */

.itemContainer,
.itemDescriptionContainer,
.itemContactContainer {
  background-color: white;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  /* padding-top: 50px;
    padding-bottom: 50px; */
  margin-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
}

.itemContainer {
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .itemContainer,
  .itemDescriptionContainer,
  .itemContactContainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .itemContainer,
  .itemDescriptionContainer,
  .itemContactContainer {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .itemContainer,
  .itemDescriptionContainer,
  .itemContactContainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .itemContainer,
  .itemDescriptionContainer,
  .itemContactContainer {
    max-width: 1170px;
  }
}

/* media */
@media (max-width: 576px) {
  .itemContainer {
    flex-direction: column;
    width: 100% !important;
  }
  .itemContainer > .itemPhotoContainer {
    width: 100% !important;
    height: 500px;
  }
  .itemContainer > .itemInfoBox {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .itemContainer {
    flex-direction: column;
    width: 100% !important;
  }
  .itemContainer > .itemPhotoContainer {
    width: 100% !important;
    height: 500px;
  }
  .itemContainer > .itemInfoBox {
    width: 100% !important;
  }
}

.itemContainer > .itemPhotoContainer {
  width: 50%;
  height: 500px;
}

.itemContainer > .itemInfoBox {
  width: 50%;
}

.itemPhoto {
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemInfoBox > div {
  text-align: start;
  padding: 15px;
  margin-bottom: 15px;
}

#message {
  margin-top: 15px;
  width: 100%;
  max-width: 100%;
  min-height: 100px;
}

.itemInfoDescription {
  padding: 15px;
  text-align: left;
  font-size: 20px;
}

.itemDescriptionHeader {
  padding: 15px;
  padding-bottom: 0px;
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;
}
.itemPage,
.itemsmainapge {
  background-color: rgb(25, 33, 81);
}

.itemInfoName {
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;
}

.itemInfoStatus,
.itemInfoVisitCount,
.itemInfoEditTime,
.itemInfoPostTime,
.itemInfoPrice {
  font-size: 20px;
  font-weight: bold;
}

.submit-message{
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.submit-message-div{
  text-align: center;
}
