html{
  background-color: rgba(25,33,81,1);
}

a{
  text-decoration: none!important;
}

body{
  min-width: 600px;
  overflow-x: scroll;
  overflow-y: scroll;
}
