#search-input{
  border-radius: 5px 0px 0px 5px;
  flex-grow: 1;
  padding: 0.5em;
  border: none;
  margin-right: 5px;
}

.input-group {
  display: flex;
  align-items: center;
}

.category-select {
  padding: 0.5em 1em;
  border-radius: 0px 0px 0px 0px;
  width: 130px;
}

#submit-search {
  margin-left: 5px;
  padding: 0.5em 1em;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: lightgray;
  cursor: pointer;
}

.searchContainer {
  background-color: #333;
  padding: 15px 15px 15px 15px;
  border-radius: 0px 0px 5px 5px ;
  background-color: rgba(25,33,81,1);
}
