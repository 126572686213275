.searchedItemContainerLink {
  text-decoration: none;
  color: black;
}

/* .searchBackgroundContainer {
  background-color: rgb(2, 5, 51);
} */

.searchedItemContainer {
  padding-right: 15px;
  padding-left: 15px;
  width: 97%;
  margin-top: 15px;
  display: inline-flex;
  border: 2px solid slategray;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(220, 220, 220, 1);;
  margin-left: 15px;
  margin-right: 15px;
}

.searchedItemPhotoContainer {
  width: 200px;
  height: 200px;
}

.searchedItemInfoContainer {
  width: 100%;
  text-align: left;
  color: #0d0c1d;
  margin-left: 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.searchedItemInfoContainer > div {
  padding: 15px;
}
.searchedItemPhoto {
  border-radius: 5px;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.searchedItemInfoName {
  font-size: 28px;
  font-weight: bold;
  border-bottom: 2px solid black;
  
}

.searchedItemInfoSecondColumn_Right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.searchedItemInfoSecondColumn_Right > div {
  margin-left: 15px;
}

.searchedItemInfoSecondColumn {
  display: flex;
  justify-content: space-between;
}

.searchedItemInfoDescription {
  padding-top: 0px !important;
  max-height: 45px;
  overflow: hidden;
}

@media (max-width: 576px) {
  .searchedItemInfoDescription {
    display: none;
  }
}

@media (max-width: 768px) {
  .searchedItemInfoDescription {
    display: none;
  }
}

@media (max-width: 992px) {
  .searchedItemInfoDescription {
    display: none;
  }
}

.searchedItemInfoPrice{
  font-size: 20px;
  font-weight: bold;
}

.itemProfielEditButton{
  border-radius: 5px;
  background-color: #e8dab2;
}
.welcomeProfileText{
  margin-left: 15px;
  color: white;
}