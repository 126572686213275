.galleryHeader {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: white;
}

div.scrollmenu {
  background-color: rgb(2, 5, 51);
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  overflow: auto;
  white-space: nowrap;

  /* margin-top: 15px; */
  margin-right: 15px;
  margin-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}

.galleryContainer {
  border-radius: 5px;
  background-color: rgba(220, 220, 220, 1);
  display: inline-block;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
}

.galleryCardName {
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  background-color: white;
  text-align: center;
  color: #0d0c1d;
  font-size: 20px;
}

.galleryCardPhoto {
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  padding: 15px;
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.galleryCardPrice {
  color: #0d0c1d;
  background-color: white;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
}

div.scrollmenu a:hover {
  background-color: #777;
}
/* 
@media (min-width: 576px) {
  .galleryHeader,
  div.scrollmenu {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .galleryHeader,
  div.scrollmenu {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .galleryHeader,
  div.scrollmenu {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .galleryHeader,
  div.scrollmenu {
    max-width: 1170px;
  }
} */

.galleryMore{
  color: white;
}