.postPhoto {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.postContainer {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 2px dotted grey;
  border-radius: 5px;
  padding-left: 15px;
  background-color: white;
}

.custom-file-input {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.uploadIcon {
  font-size: 200px;
  color: rgba(100, 100, 100, 0.5);
}

.postInputContainer {
  aspect-ratio: 1/1;
  border-left: 2px dotted grey;
  display: flex;
  flex-direction: column;
  align-items: flex;
  justify-content: space-around;
}

.headingText {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.postInput {
  width: 97%;
  border-bottom: 2px dotted grey;
  border-top: 2px dotted grey;
  border-left: 2px dotted grey;
  border-right: 2px dotted grey;
  box-shadow: 5px 10px #888888;
  border-radius: 5px;
}

.postInputHeader {
  border-bottom: 5px solid grey;
}

.postInputSubmit {
  border-radius: 5px;
  width: 97%;
  font-weight: bold;
}

textarea.postInput {
  min-height: 2em;
  max-height: 20em;
}

/* .postContainer{
    background-color: aqua;
} */

.postpage {
  background-color: rgb(25, 33, 81);
}
.profilePage{
  background-color: rgb(25, 33, 81);
}
.editPage{
  background-color: rgb(25, 33, 81);
}