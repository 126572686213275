.messagePage {
  background-color: rgb(25, 33, 81);
  color: white;
}

/* .allMessageContainer{
    background-color: white;
} */

.messageRowContainer {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
}

.targetNameContainer {
  border: 3px solid grey;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  aspect-ratio: 1/1;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.targetNameContainerText {
  font-size: 20px;
  word-break: break-all;
  text-align: center;
}

.recentMessageContainer {
  border-radius: 5px;
  border: 3px solid grey;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.recentMessage {
  color: black;
  padding: 15px;
}

.messageArea {
  padding: 15px;
  width: 100%;
  min-height: 100px;
  border: 3px solid grey;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.sendMessageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

#receiver {
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  height: 83%;
  font-weight: bold;
  font-size: 28px;
}
