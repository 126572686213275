.stickyTopSection {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgba(25, 33, 81, 1);
}

.homePage {
  background-color: rgba(25, 33, 81, 1);
}
