.jumbotron-container {
  aspect-ratio: 5/1;
  color: white;
  border-radius: 5px;
  padding: 50px;
  background-color: rgb(2, 5, 51);
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  margin-left: 15px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: bottom 1px right 50px;
}

@media screen and (max-width: 768px) {
  .jumbotron-container {
    background-size: 200px;
    background-position: bottom 1px right -20px;
    /* color: red; */
  }
}

@media screen and (max-width: 468px) {
  .jumbotron-container {
    background-size: 200px;
    background-position: bottom 1px right -100px;
  }
}

#rocket-animation {
  display: inline-block;
  position: relative;
  top: 0px;
  left: 0px;
  animation: example 10s linear infinite;
}

@keyframes example {
  0% {
    top: 0px;
    left: 0px;
    transform: rotate(0deg);
  }

  30% {
    color: transparent;
    top: -100px;
    left: 100px;
    transform: rotate(15deg);
  }
  71% {
    color: transparent;
    top: 50px;
    left: -50px;
    transform: rotate(0deg);
  }
}


.bannerTextContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.hiddenTextBanner{
  color: transparent;
}